import { makeStyles, lighten, darken } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    root: {
      
    },
    menuTitleIcon:{
      paddingLeft:4,
      paddingRight:4,
      color: theme.palette.amano.main
    },
    title: {
      fontWeight:500,
      paddingLeft:5,
      paddingRight:5,
      color: theme.palette.amano.main,
      marginBottom:5,
    },
    titleIcon:{
      marginRight:-25,
    },
    titleText:{
      marginLeft:5
    },
    scopeButton: {
      float:"right",
      margin:4,
      fontSize:"smaller"
      
    },
    listContainer:{
      maxHeight:"30vh",
      overflow:"auto"
    },
    facilityListItem: {
      '&.scope.selected':{
        backgroundColor:lighten(theme.palette.amano.main,.6),
        color:darken(theme.palette.amano.main,.4),
      }
    },
    facilityListItemIcon: {
      '&.selected':{
        color:theme.palette.amano.main,
      }
    },
    customBadge: {
      backgroundColor: theme.palette.amano.warnings.low,
      color: "white",
      padding:0
    },
}));

export default useStyles;