import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { onFiltersChanged } from "../../../reducers/contracts/contractReducer";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useHasPermissions from "../../../hooks/useHasPermissions";
import RoutineService from "../../../services/RoutineService";
import ListView from "../../ListView/ListView";
import { useRoutineData } from "../../../providers/RoutineProvider";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";

const routineService = new RoutineService(apiClient);

const RoutineServiceContainer = ({
  showTitle,
  entityId,
  onAddClick,
  onEditClick
}) => {
  const [routines, setRoutines] = useState([]);
  const enqueueSnackbar = useEnqueueSnackbar();
  const ITEM_LIMIT = 15;
  const { routineData } = useRoutineData();
  const [values, dispatch] = routineData;
  const { showDialog } = useConfirmationDialog();
  const history = useHistory();
  const { hasPermissions } = useHasPermissions();
  const DeleteRoutinePermission = hasPermissions(["routines.delete"]);
  const EditRoutinePermission = hasPermissions(["routines.edit"]);
	const AddRoutinePermission = hasPermissions(["routines.add"]);
  const LPREnabled = useFeatureFlag('LPR');

  useEffect(() => {
    fillRoutineList();
  }, [entityId, values?.routinePage, values?.searchTerm]);

  const fillRoutineList = useCallback(async () => {
    let response;
    try {
      response = await routineService.getRoutinesForEntity(
        entityId,
        ITEM_LIMIT,
        values?.routinePage ? (values.routinePage - 1) * ITEM_LIMIT : 0,
        values.searchTerm
      );
    } catch {
      enqueueSnackbar("Failed to retrieve routines", {
        variant: "error",
        tag: "fetchRoutinesError"
      });
      return;
    }
    setRoutines({
      totalCount: response.data?.totalCount ?? 0,
      display: response.data.collection?.map(routine => {
        return {
          id: routine.id,
          name: routine.name,
					eventid: routine.systemEventId,
          condition: routine.eventName ?? " ",
          actions: routine.actions?.length?.toString()
        };
      })
    });
  }, [entityId, values?.routinePage, values?.searchTerm]);

  const deleteRoutine = async routineId => {
    const response = await showDialog({
      title: `Delete Routine`,
      message: "Are you sure you want to delete this routine?",
      buttons: [
        { name: "Yes", color: "primary" },
        { name: "No", color: "secondary" }
      ]
    });
    if (response === "No") return;
    try {
      await routineService.deleteRoutine(routineId);
    } catch {
      enqueueSnackbar("Failed to delete routine", {
        variant: "error",
        tag: "deleteRoutineError"
      });
      return;
    }
    fillRoutineList();
  };

  const handlePageChange = (e, page) => {
    dispatch({
      type: onFiltersChanged,
      payload: { routinePage: page }
    });
  };

  const handleSearchChange = term => {
    dispatch({
      type: onFiltersChanged,
      payload: { searchTerm: term }
    });
  };

  const handleDeleteButton = routineId => {
    deleteRoutine(routineId);
  };

  return (
    <>
      <ListView
        handleAddButton={onAddClick}
        handleEditButton={onEditClick}
        handleDeleteButton={handleDeleteButton}
				deleteButtonToggle={DeleteRoutinePermission ? true : false}
        editButtonToggle={EditRoutinePermission ? true : false}
				editButtonEnabledFunc={(row) => {
					return row.eventid === 29 ? LPREnabled : true;
				}}
        titleBarTitleText="Routines"
        titleBarToggle={showTitle}
        titleBarTextToggle={true}
        titleBarAddButtonToggle={AddRoutinePermission ? true : false}
        addButtonText=""
        showTableHead={true}
        paginationToggle={true}
        data={routines?.display ?? []}
        editButtonText={"Edit"}
        rowsToShowPerPage={[15, 25, 50]}
        rowsToShowDefault={15}
        handlePageChange={handlePageChange}
        currentPage={values?.routinePage}
        totalCount={Math.ceil(routines.totalCount / ITEM_LIMIT)}
        queryPagination={true}
        showSearch={true}
        handleSearchChange={handleSearchChange}
        searchValue={""}
        additionalButtons={[
          {
            title: "history",
            handleClick: id => {
              history.push({
                pathname: "/routineservice/history",
                state: { routineId: id }
              });
            }
          }
        ]}
        sortCaseInsensitive={true}
      />
    </>
  );
};

RoutineServiceContainer.defaultProps = {
  onSelect: () => {},
  onAddClick: () => {},
  onEditClick: () => {},
  showTitle: true
};

RoutineServiceContainer.propTypes = {
  onSelect: PropTypes.func,
  onAddClick: PropTypes.func,
  onEditClick: PropTypes.func,
  showTitle: PropTypes.bool
};

export default RoutineServiceContainer;
