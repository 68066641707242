import React from "react";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import { useStyles } from "./styles";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell as alertIcon } from "@fortawesome/pro-light-svg-icons";
import { ALERT_SEVERITY, ROUTINE_TYPE } from "../../../../constants/index";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ActionForm from "./ActionForm";

export const alertAddEditSchema = Yup.object().shape({
  SeverityID: Yup.string().required("Required"),
});

const AlertForm = ({ handleSave, handleCancel, event, events, action }) => {
  const classes = useStyles();

  const formatAndSave = (values) => {
    var alert = {
      payload: {
        SeverityID: values.SeverityID,
        SystemEventID: values.SystemEventID,
        Status: "NotYetClaimed", //default status for all generated alerts
      },
      actionTypeID: ROUTINE_TYPE.Alert,
      order: action?.order ?? 0,
    };
    handleSave(alert);
  };

  let defaults = {
    SeverityID: "",
    SystemEventID: event ?? 0,
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: defaults,
    resolver: yupResolver(alertAddEditSchema),
  });

  React.useEffect(() => {
    if (action) reset(action?.payload);
  }, [action]);

  return (
    <>
      <ActionForm
        handleSave={formatAndSave}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        icon={
          <FontAwesomeIcon icon={alertIcon} />
        }
        edit={action}
        primaryText={"Alert"}
        secondaryText={"Create an alert on the dashboard"}
      >
        <Grid container className={clsx([classes.drawerContainer])} spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Controller
              name="SeverityID"
              control={control}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  FormLabel="Severity Level"
                  variant="outlined"
                >
                  <Grid container>
                    <Grid container item xs={12} sm={4} alignItems="center">
                      <Typography
                        variant="subtitle1"
                        id="severityLevel"
                        className={clsx([classes.severityLabel])}
                      >
                        Severity Level
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Select
                        {...field}
                        fullWidth
                        displayEmpty
                        data-id={"severityLevelSelect"}
                        data-value={field?.value}
                        className={clsx([classes.severityDropdown])}
                        variant="outlined"
                        inputProps={{
                          id: "severity-dropdown",
                        }}
                        error={!!errors.SeverityID}
                        helperText={
                          errors.SeverityID && errors.SeverityID.message
                        }
                      >
                        {Object.keys(ALERT_SEVERITY)?.map((item, index) => {
                          if (item != "Unset") {
                            return (
                              <MenuItem
                                data-id={`${item}-severity-item`}
                                key={item}
                                value={index + 1}
                                className={clsx(`${item}-menu-item`)}
                                name="severityLevel"
                              >
                                {item}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                      {errors.SeverityID && (
                        <div className={clsx([classes.selectError])}>
                          {errors.SeverityID.message}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Controller
              name="SystemEventID"
              control={control}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  FormLabel="Alert Event"
                  variant="outlined"
                >
                  <Grid container>
                    <Grid container item xs={12} sm={4} alignItems="center">
                      <Typography
                        variant="subtitle1"
                        id="alertEvent"
                        className={clsx([classes.severityLabel])}
                      >
                        Alert Event
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Select
                        {...field}
                        fullWidth
                        displayEmpty
                        data-id={"systemEventSelect"}
                        data-value={field?.value}
                        className={clsx([classes.eventDropdown])}
                        variant="outlined"
                        inputProps={{
                          id: "event-dropdown",
                        }}
                        error={!!errors.SystemEventID}
                        helperText={
                          errors.SystemEventID && errors.SystemEventID.message
                        }
                      >
                        {events?.map((item) => {
                          return (
                            <MenuItem
                              key={item.value}
                              value={item.value}
                              data-id={`${item}-event-item`}
                              className={clsx(`${item.name}-event-item`)}
                              name="systemEventID"
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors.SeverityID && (
                        <div className={clsx([classes.selectError])}>
                          {errors.SeverityID.message}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </ActionForm>
    </>
  );
};
export default AlertForm;
