import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: { 
    padding: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0)
    },
  },
  drawer :{
  },
  drawerPaper: {
      [theme.breakpoints.up('sm')]: {
        width: "40%",
        flexShrink: 0,
      },
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        flexShrink: 0,
      },    
  },

}));
