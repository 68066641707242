import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ko from "knockout";
import AppConfig from "AppConfig";
import "./ReportRenderer.css";
import "devexpress-reporting/dx-webdocumentviewer";
import { ActionId } from 'devexpress-reporting/dx-webdocumentviewer';
import useManagedAuth from "../../hooks/useManagedAuth";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import { useSelector, shallowEqual } from "react-redux";
import { USER_TYPES } from "../../constants/index";
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils";

const ReportRenderer = ({ className, reportName }) => {
  const viewerRef = useRef();
  const { token, refreshToken } = useManagedAuth();
  const { facilityID } = useCurrentFacility();
  const facilityIDs = useSelector((state) => state.entityScope.available) ?? [];
  const [ajaxInitialized, setAjaxInitialized] = useState(false);
  const currentUser = useSelector((state) => state.user, shallowEqual);
  const facilityGroupID = useSelector((state) => state.entityScope?.facilityGroupId)

  const getReportUri = () => {
    let pairs = {};
    switch (reportName?.toLowerCase()) {
      case "validationstatement":
        if (currentUser.UserType == USER_TYPES.ValidationAccount)
          pairs["userID"] = currentUser.UserID;
    }
    // this will either be all facilities in group OR a single facility ID:
    if(facilityGroupID && facilityIDs.length > 0) {
      pairs["facilityID"] = facilityIDs.map((f)=>f.id).join();
    } else {
      pairs["facilityID"] = facilityID;
    }
    pairs["facilityGroupID"] = facilityGroupID;
    pairs["currentFacilityID"] = facilityID;
    
    const params = new URLSearchParams(pairs);
    return encodeURI(`${reportName}?${params.toString()}`);
  };

  useEffect(() => {
    if (!reportName || !ajaxInitialized) {
      return;
    }

    // forgive me
    ko.applyBindings(
      {
        reportUrl: getReportUri(),
        requestOptions: {
          host: `${AppConfig.externalServices.baseURL}/reports/`,
          invokeAction: "DXXRDV",
        },
        callbacks: {
          OnExport: function(src, event) {
            event.FormData["access_token"] = token;
            event.RequestUrl = `${AppConfig.externalServices.baseURL}/reports/DXXRDV/export`;
          },
          CustomizeMenuActions: function(src, event) {
              // Get the page navigation actions and hide them.
              var actionPrint = event.GetById(
                  ActionId.Print
              );
              if (actionPrint) actionPrint.visible = false;
              var actionPrintPage = event.GetById(
                ActionId.PrintPage
            );
            if (actionPrintPage) actionPrintPage.visible = false;
          }
        },
      },
      viewerRef.current
    );

    return () => ko.cleanNode(viewerRef.current);
  }, [reportName, ajaxInitialized, token]);

  useEffect(() => {
    if (!token) {
      return;
    }

    ajaxSetup.ajaxSettings = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      error: function(jqXHR, textStatus, errorThrown) {
        if (jqXHR.status == 401) {
          refreshToken();
        }
      },
    };

    setAjaxInitialized(true);
  }, [token]);

  return (
    <div className={className}>
      <div
        className="viewport"
        ref={viewerRef}
        data-bind="dxReportViewer: $data"
      />
    </div>
  );
};

ReportRenderer.propTypes = {
  className: PropTypes.string,
  reportName: PropTypes.string.isRequired,
};

export default ReportRenderer;
