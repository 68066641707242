import React from "react";
import {
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  Tooltip
} from "@material-ui/core";
import _ from "lodash";
import { useStyles } from "./styles";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxArchive as inventoryIcon } from "@fortawesome/pro-light-svg-icons";
import { ROUTINE_TYPE } from "../../../../constants/index";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ActionForm from "./ActionForm";

export const inventoryAddEditSchema = Yup.object().shape({
  TicketsOlderThanDays: Yup.number()
    .min(1, "Value must be from 1 to 365")
    .max(365, "Value must be from 1 to 365")
    .typeError("Value must be a number")
    .required("Required"),
  PassesOlderThanDays: Yup.number()
    .min(1, "Value must be from 1 to 365")
    .max(365, "Value must be from 1 to 365")
    .typeError("Value must be a number")
    .required("Required"),
});

const InventoryActionForm = ({ handleSave, handleCancel, action }) => {
  const classes = useStyles();

  let defaults = {
    TicketsEnabled: false,
    TicketsOlderThanDays: 1,
    PassesEnabled: false,
    PassesOlderThanDays: 1
  };

  const formatAndSave = (values) => {
    var template = {
      payload: {
        TicketsEnabled: values.TicketsEnabled,
        TicketsOlderThanDays: values.TicketsOlderThanDays,
        PassesEnabled: values.PassesEnabled,
        PassesOlderThanDays: values.PassesOlderThanDays,
      },
      actionTypeID: ROUTINE_TYPE.Inventory,
      order: action?.order ?? 0,
    };
    handleSave(template);
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
    watch,
  } = useForm({
    defaultValues: defaults,
    resolver: yupResolver(inventoryAddEditSchema),
  });
  const watchFields = watch();

  React.useEffect(() => {
    if (action) reset(action?.payload);
  }, [action]);

  return (
    <>
      <ActionForm
        handleSave={formatAndSave}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        icon={
          <FontAwesomeIcon icon={inventoryIcon} />
        }
        edit={action}
        primaryText={"Inventory"}
        secondaryText={"Archive Credentials"}
      >
        <Grid container spacing={2} className={clsx([classes.drawerContainer])}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Controller
              name="TicketsEnabled"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      id="ticketsEnabled"
                      color="primary"
                      data-testid="ticketsswitch"
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                      checked={field.value}
                      data-checked={field.value}
                    />}
                  label="Tickets"
                />
              )}
            />
            <Controller
              name="TicketsOlderThanDays"
              control={control}
              render={({ field }) => (
                <Grid container>
                  <Grid item xs={12} sm={8}>
                    <Tooltip
                      placement="top-end"
                      title="Archives tickets based on the entry date."
                    >
                      <TextField
                        {...field}
                        disabled={!watchFields.TicketsEnabled}
                        data-id={"TicketsOlderThanDays"}
                        data-testid={"TicketsOlderThanDays"}
                        variant="outlined"
                        margin="normal"
                        label="Entry Date Older Than"
                        required
                        fullWidth
                        id="TicketsOlderThanDays"
                        error={!!errors.TicketsOlderThanDays}
                        helperText={(errors.TicketsOlderThanDays && errors.TicketsOlderThanDays.message) ||
                          "Days"}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Controller
              name="PassesEnabled"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      id="passesEnabled"
                      color="primary"
                      data-testid="passesswitch"
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                      checked={field.value}
                      data-checked={field.value}
                    />}
                  label="Passes"
                />
              )}
            />
            <Controller
              name="PassesOlderThanDays"
              control={control}
              render={({ field }) => (
                <Grid container>

                  <Grid item xs={12} sm={8}>
                    <Tooltip
                      placement="top-end"
                      title="Archives based on the expiration date of the pass."
                    >
                      <TextField
                        {...field}
                        disabled={!watchFields.PassesEnabled}
                        data-id={"PassesOlderThanDays"}
                        data-testid={"PassesOlderThanDays"}
                        variant="outlined"
                        margin="normal"
                        label="Expiration Date Older Than"
                        required
                        fullWidth
                        id="PassesOlderThanDays"
                        error={!!errors.PassesOlderThanDays}
                        helperText={(errors.PassesOlderThanDays && errors.PassesOlderThanDays.message) ||
                          "Days"}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
        </Grid>
      </ActionForm>
    </>
  );
};
export default InventoryActionForm;