import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import clsx from "clsx";
import { useStyles } from "./style";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { useCashierContext } from "../../../providers/CashierProvider";
import { onFiltersChanged } from "../../../reducers/cashier/roamingCCTerminalPageReducer";
import useRoamingCCTerminalState from "../../../state/slices/cashier/useRoamingCCTerminalState";
import RoamingCCTerminalsTable from "../RoamingCCTerminalsTable";

function getRowsByHeight(height) {
  return Math.round(height / 80);
}

const RoamingCCTerminalContainer = ({ onAddClick, onEditClick }) => {
  const classes = useStyles();
  const { roamingCCTerminals } = useRoamingCCTerminalState();
  const { roamingCCTermianlPageData } = useCashierContext();
  const { height } = useWindowDimensions();
  const [itemLimit, setItemLimit] = useState(getRowsByHeight(height));
  const { hasPermissions } = useHasPermissions();
  const AddPermission = hasPermissions(["roamingcreditcardterminalsetup.add"]);
  const [values, dispatch] = roamingCCTermianlPageData;

  const formatListDatas = (roamingCCTerminals) => {
    if (!roamingCCTerminals) return [];
    return roamingCCTerminals
      .slice((values?.page - 1) * itemLimit, values?.page * itemLimit)
      .map((roamingCCTerminals) => {
        return {
          id: roamingCCTerminals.roamingCCTerminalID,
          name: roamingCCTerminals.displayName,
          terminalid: roamingCCTerminals.terminalSerialNo,
        };
      });
  };
  const [currentRoamingCCTerminals, setRoamingCCTerminals] = useState({
    totalCount: 0,
    collection: [],
  });

  useEffect(() => {
    if (height) {
      setItemLimit(height > 0 ? getRowsByHeight(height) : 0);
    }
  }, [height]);

  useEffect(() => {
    const listOfRoamingCCTerminals = formatListDatas(roamingCCTerminals);
    setRoamingCCTerminals((prev) => ({
      ...prev,
      collection: listOfRoamingCCTerminals,
    }));
  }, [values.page, itemLimit]);

  useEffect(() => {
    const listOfRoamingCCTerminals = formatListDatas(roamingCCTerminals);
    setRoamingCCTerminals({
      totalCount: roamingCCTerminals.length,
      collection: listOfRoamingCCTerminals,
    });
  }, [roamingCCTerminals]);

  const handlePageChange = (e, page) => {
    dispatch({
      type: onFiltersChanged,
      payload: { page: page },
    });
  };

  return (
    <>
      <Grid
        container
        className={clsx(
          "roamingccterminal-container",
          classes.roamingCCTerminalContainer
        )}
      >
        <Paper
          elevation={3}
          className={clsx("roamingccterminal-paper", classes.paper)}
        >
          <Grid item xs={12} container className={classes.cardHeader}>
            <Grid item xs={8}>
              <Typography className={clsx("title", classes.title)}>
                {"CREDIT CARD TERMINALS"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              id="button-component"
              className={clsx("button-component", classes.buttonContainer)}
            >
              {AddPermission && (
                <Button
                  className={clsx("btn-add", classes.addButton)}
                  data-id="add-btn"
                  data-testid="add-btn"
                  variant="contained"
                  color="primary"
                  onClick={onAddClick}
                >
                  {"Add"}
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Pagination
              className={clsx(
                "pagination-container",
                classes.paginationContainer
              )}
              page={values.page ?? 1}
              onChange={handlePageChange}
              count={Math.ceil(
                currentRoamingCCTerminals?.totalCount / itemLimit
              )}
              color="primary"
              shape="rounded"
            />
          </Grid>
          {
            <RoamingCCTerminalsTable
              roamingCCTerminals={currentRoamingCCTerminals?.collection ?? []}
              onSelect={onEditClick}
            />
          }
        </Paper>
      </Grid>
    </>
  );
};

RoamingCCTerminalContainer.defaultProps = {
  onAddClick: () => {},
  onEditClick: () => {},
};

RoamingCCTerminalContainer.propTypes = {
  onAddClick: PropTypes.func,
  onEditClick: PropTypes.func,
};

export default RoamingCCTerminalContainer;
