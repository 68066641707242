import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Button, Drawer, TextField, Paper } from "@material-ui/core";
import AddRoutineAction from "./AddRoutineAction";
import _ from "lodash";
import clsx from "clsx";
import { useStyles } from "./styles";
import apiClient from "../../../auth/apiClient";
import RoutineService from "../../../services/RoutineService";
import GPIOService from "../../../services/GPIOService";
import ReversingLaneService from "../../../services/ReversingLaneService";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import RoutineWhenContainer from "./RoutineWhen";
import RoutineThenContainer from "./RoutineThen";
import { useParams } from "react-router-dom";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";
import { ROUTINE_TYPE } from "../../../constants";
import { Tooltip } from "@material-ui/core";

const gpioService = new GPIOService(apiClient);
const routineService = new RoutineService(apiClient);
const reversingLaneService = new ReversingLaneService(apiClient);


const RoutineComposer = ({ routineId, onCancel }) => {
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
  const defaultRoutine = {
    id: routineId ?? 0,
    entityId: facilityID,
    name: "",
    cronSchedule: null,
    systemEventId: null,
    actions: []
  };
  const [currentRoutine, setCurrentRoutine] = useState(defaultRoutine);
  const [currentAction, setCurrentAction] = useState();
  const [isEvent, setIsEvent] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [conditionalValid, setConditionalValid] = useState(true);
  const [isSaveEnabled, setIsSaveEnabled] = useState();
  const [actionableEvents, setActionableEvents] = useState([]);
  const [reversingLanes, setReversingLanes] = useState();
  const [saveToolTip, setSaveToolTip] = useState();

  const enqueueSnackbar = useEnqueueSnackbar();
  const { showDialog } = useConfirmationDialog();
  const LPREnabled = useFeatureFlag('LPR');

  const GetRoutine = async () => {
    try {
      var result = await routineService.getRoutineByID(routineId);
      setCurrentRoutine(result?.data);
      if (result.data?.systemEventId > 0) setIsEvent(true);
      else if (result.data?.cronSchedule) setIsSchedule(true);
    } catch (error) {
      enqueueSnackbar("Failed to get routine", {
        variant: "error",
        tag: "getSystemEventsError"
      });
    }
  };

  const GetActionableEvents = async () => {
    try {
      var result = await gpioService.GetActionableEvents();
      var events = result.data
      .filter(e => LPREnabled ? true : e.systemEventID !== 29)
      .map(event => {
        return { name: event.description, value: event.systemEventID };
      });
      setActionableEvents([...events]);
    } catch (error) {
      enqueueSnackbar("Failed to get system events", {
        variant: "error",
        tag: "getSystemEventsError"
      });
    }
  };

  const formatActions = (actions, value) => {
    if (value.order > 0) {
      var index = actions.findIndex(action => action.order == value.order);
      if (index >= 0) actions[index] = value;
    } else {
      value.order = actions.length + 1;
      actions.push(value);
    }
    return actions;
  };

  const handleSwitch = async fromSchedule => {
    if (fromSchedule ? isEvent : isSchedule) {
      const response = await showDialog({
        title: `Change Routine Type`,
        message:
          "This will clear out any existing configuration. Are you sure?",
        buttons: [
          { name: "Yes", color: "primary" },
          { name: "No", color: "secondary" }
        ]
      });
      if (response === "No") return;
    }
    setCurrentRoutine({ ...defaultRoutine, name: currentRoutine.name });
    if (fromSchedule) {
      setIsEvent(false);
      setIsSchedule(true);
    } else {
      setIsSchedule(false);
      setIsEvent(true);
    }
  };

  const handleAddAction = () => {
    setCurrentAction(null);
    setDrawerOpen(true);
  };

  const handleEditAction = action => {
    setCurrentAction(action);
    setDrawerOpen(true);
  };

  const handleClose = () => {
    setDrawerOpen(false);
  };

  const handleAddClicked = values => {
    var actions = [...currentRoutine.actions];
    if (values) {
      actions = formatActions(actions, values);
    }
    setCurrentRoutine({ ...currentRoutine, actions: actions });
    setDrawerOpen(false);
  };

  const handleSave = async () => {
    try {
      if (currentRoutine?.id != null && currentRoutine?.id > 0) {
        var response = await routineService.updateRoutine(currentRoutine);
        setCurrentRoutine(response?.data);
      } else {
        var response = await routineService.createRoutine(currentRoutine);
        setCurrentRoutine(response?.data);
      }
      setDrawerOpen(false);
    } catch (error) {
      enqueueSnackbar("Failed to save routine", {
        variant: "error",
        tag: "saveRoutineError"
      });
    }
  };

  const onSaveClick = async () => {
    await handleSave();
    onCancel();
  };

  useEffect(() => {
    GetActionableEvents();
    if (routineId && routineId > 0) GetRoutine();
  }, [routineId]);

  const frequencyIsLessThanOncePerHour = () => {
    if (currentRoutine?.cronSchedule == null) return true;

    const minuteSetting = currentRoutine?.cronSchedule.split(" ")[0];

    // should be one number, otherwise it's more than once per hour
    if (minuteSetting.indexOf("*") > -1 || minuteSetting.indexOf(",") > -1 || minuteSetting.indexOf("-") > -1) {
      setSaveToolTip("This action cannot be scheduled for more than once an hour")
      return false;
    } 
    
    return true;
  }

  const snapshotActionIsValid = () => {
    if (currentRoutine?.actions.some(x => x.actionTypeID === ROUTINE_TYPE.OCCUPANCY_COUNT || x.actionTypeID === ROUTINE_TYPE.NON_RESET_COUNT)) {
      return frequencyIsLessThanOncePerHour();
    }

    return true;
  }

  useEffect(() => {
    setSaveToolTip("");
    setIsSaveEnabled(
      ((isSchedule && currentRoutine?.cronSchedule != null) ||
        (isEvent && conditionalValid && currentRoutine?.systemEventId != null)) &&
        currentRoutine?.name != null &&
        currentRoutine?.name != "" &&
        snapshotActionIsValid()
    );

    if (isSchedule && currentRoutine?.cronSchedule === null) {
      setSaveToolTip("Schedule is Required");
    }

    if (currentRoutine?.name === null || currentRoutine?.name === "") {
      setSaveToolTip("Routine Name is Required");
    }
  }, [currentRoutine, isSchedule, isEvent, conditionalValid]);

  useEffect(() => {
    getReversingLanes();
  }, []);

  const getReversingLanes = async () => {
    try {
      var result = await reversingLaneService.getReversingLanes(facilityID);
      setReversingLanes({
        facilityID : facilityID,
        lanes : result.data?? []
      });
    } catch (err){
      enqueueSnackbar("Unable to retrieve reversing lanes for this entity", {
        variant: "error",
        tag: "FailedToFindReversingLanesForEntity",
      });
    }
  };  

  return (
    <div data-testid="routine-composer">
      <Drawer anchor="right" open={drawerOpen} onClose={handleClose}>
        <AddRoutineAction
          routine={currentRoutine}
          handleSave={handleAddClicked}
          handleClose={handleClose}
          action={currentAction}
          events={actionableEvents}
          reversingLanes={reversingLanes}
          isEvent={isEvent}
        />
      </Drawer>
      <Paper className={clsx([classes.composerContainer])}>
        <Grid container className={clsx([classes.root])}>
          <Grid item xs={6}>
            <TextField
              className={clsx([classes.routineName])}
              value={currentRoutine.name}
              data-value={currentRoutine.name}
              data-id={"routineName"}
              data-testid={"routineName"}
              variant="outlined"
              name="routineName"
              fullWidth
              type="text"
              label="Routine Name"
              onChange={e =>
                setCurrentRoutine({ ...currentRoutine, name: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container justify="flex-end">
              <Tooltip title={saveToolTip}>
              <span>
                <Button
                  data-id={"saveBtn"}
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={isSaveEnabled ? onSaveClick : null}
                  disabled={!isSaveEnabled}
                >
                  Save
                </Button>
              </span>
              </Tooltip>
              <Button
                data-id={"cancelBtn"}
                size="small"
                variant="contained"
                className={clsx([classes.cancelButton])}
                onClick={() => {
                  onCancel(false);
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
          <RoutineWhenContainer
            currentRoutine={currentRoutine}
            setCurrentRoutine={setCurrentRoutine}
            handleSwitch={handleSwitch}
            events={actionableEvents}
            isSchedule={isSchedule}
            isEvent={isEvent}
            setConditionalValid={setConditionalValid}
          />
          <RoutineThenContainer
            currentRoutine={currentRoutine}
            setCurrentRoutine={setCurrentRoutine}
            handleAddAction={handleAddAction}
            handleEditAction={handleEditAction}
            isSaveEnabled={isSaveEnabled}
            reversingLanes={reversingLanes}            
          />
        </Grid>
      </Paper>
    </div>
  );
};

RoutineComposer.defaultProps = {
  onCancel: () => {}
};

RoutineComposer.propTypes = {
  onCancel: PropTypes.func
};

export default RoutineComposer;
