import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  actionTitle: {
    margin: theme.spacing(1),
  },
  actionButtonList: {
    padding: 0,
    marginTop: 0,
    borderTop: "1px solid",
  },
  actionListItem: {
    borderBottom: "1px solid",
  },
  buttonContainer: {
    marginTop: 20,
  },
  closeBtn: {
    float: "right",
    marginRight: 10,
  },
  listIcon: {
    minWidth: theme.spacing(4),
  },
  listText: {
    marginRight: theme.spacing(4),
    maxWidth: theme.spacing(7),
    width: "-webkit-fill-available",
  },
  listDescription: {},
}));
