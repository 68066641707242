import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  RateComposer: {

  },
  RateComponent: {
    color: theme.palette.type === "dark" ? "#ccf1ff" : "navy",
    backgroundColor: theme.palette.type === "dark" ? "#333333" : "white",
    margin: "0px 0px 20px 0px",
    padding: "5px 5px 5px 5px",
    position: "relative",
    borderRadius: "4px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  RateComponentHeader: {},
  RateComponentHeaderTitle: {
    fontSize: "18px",
  },
  RateComponentHeaderSummary: {
    fontSize: "15px",
  },
  RateComponentRemoveButton: {
    position: "absolute",
    right: "0px",
    top: "0px",
    margin: "2px 2px 2px 2px",
    transitionDuration: "0.25s",
    transitionProperty: "color",
    "&:hover": {
      color: "red",
    },
  },
  RateComponentEditButton: {
    position: "absolute",
    right: "48px",
    top: "0px",
    margin: "2px 2px 2px 2px",
    transitionDuration: "0.25s",
    transitionProperty: "color",
    "&:hover": {
      color: theme.palette.type === "dark" ? "white" : "black",
    },
  },
  PriceComponent: {
    color: theme.palette.type === "dark" ? "#ccf1ff" : "navy",
    backgroundColor: theme.palette.type === "dark" ? "#333333" : "white",
    margin: "0px 0px 20px 0px",
    padding: "5px 5px 5px 5px",
    position: "relative",
    display: "inline-block",
  },
  PriceComponentRemoveButton: {
    position: "absolute",
    right: "0px",
    top: "0px",
    margin: "2px 2px 2px 2px",
    transitionDuration: "0.25s",
    transitionProperty: "color",
    "&:hover": {
      color: "red",
    },
  },
  RateComponentSection: {
    padding: "15px 0px 15px 0px",
  },
  RateWizardTypeSelectButton: {
    display: "block",
  },
  Spacer: {
    marginBottom: "20px",
  },
  RateContainer: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "10%", 
      paddingRight: "10%", 
      paddingTop: "2%", 
      paddingBottom: "2%",
    }
  }
}));

export default styles;
