import React from "react";
import { Grid, Button, List, ListItem, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import Title from "../../../Title";
import { useStyles } from "./styles";
import { ROUTINE_TYPE, ALERT_SEVERITY } from "../../../../constants/index";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const RoutineThen = ({
  currentRoutine,
  setCurrentRoutine,
  handleAddAction,
  handleEditAction,
  isSaveEnabled,
  reversingLanes,
}) => {
  const classes = useStyles();
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      currentRoutine?.actions,
      result.source.index,
      result.destination.index
    );

    setCurrentRoutine({ ...currentRoutine, actions: items });
  };

  const formatAction = (action) => {
    switch (action.actionTypeID) {
      case ROUTINE_TYPE.Alert:
        var severity = ALERT_SEVERITY.Unset;
        for (let key in ALERT_SEVERITY)
          if (ALERT_SEVERITY[key] == action.payload?.SeverityID) {
            severity = key;
          }
        return (
          <>
            Create an alert with{" "}
            <span className={clsx([classes.actionText])}>{severity}</span>{" "}
            severity
          </>
        );
      case ROUTINE_TYPE.Email:
        return (
          <>
            Send email to{" "}
            <span className={clsx([classes.actionText])}>
              {action.payload?.ToAddress}
            </span>
          </>
        );
      case ROUTINE_TYPE.SMS:
        return (
          <>
            Send text to{" "}
            <span className={clsx([classes.actionText])}>
              {action.payload?.To}
            </span>{" "}
            at {action.payload?.Number}
          </>
        );
      case ROUTINE_TYPE.OCCUPANCY_COUNT:
        return (
          <>
            Save occupancy count snapshot
          </>
        );
      case ROUTINE_TYPE.NON_RESET_COUNT:
        return (
          <>
            Save non-reset count snapshot
          </>
        );
      case ROUTINE_TYPE.ReverseLane:
        return (
          <>
            Switch{" "}
            <span className={clsx([classes.actionText])}>
              {`${reversingLanes?.lanes?.find((data) => data.laneID == action.payload?.LaneID)?.laneName}`}
            </span>{" "}
            to{" "}
            <span className={clsx([classes.actionText])}>
              {action.payload?.Mode}
            </span>{" "}
          </>
        );
      case ROUTINE_TYPE.Inventory:
        return (
          <>
            Archive credentials
          </>
        );
      default:
        return "Unrecognized action type " + action.actionTypeID;
    }
  };

  const handleDeleteAction = (order) => {
    var index = currentRoutine.actions?.findIndex((a) => a.order === order);
    if (index >= 0) {
      var newActions = currentRoutine.actions;
      newActions.splice(index, 1);
      setCurrentRoutine({ ...currentRoutine, actions: newActions });
    }
  };

  return (
    <Grid container className={clsx([classes.routineThenContainer])}>
      <Grid container item xs={6} alignItems="center">
        <Title>
          <h5 className={clsx([classes.whenThenText])}>Then...</h5>
        </Title>
      </Grid>
      <Grid container item xs={6}>
        <Grid container justify="flex-end" alignItems="center">
          <Button
            data-id={"addActionBtn"}
            onClick={handleAddAction}
            color="primary"
            size="small"
            variant="contained"
            disabled={!isSaveEnabled}
          >
            Add Action
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <hr />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {currentRoutine?.actions?.map((item, index) => (
                  <Draggable
                    key={`${item.order}`}
                    draggableId={`${item.order}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <ListItem
                        data-id={`${item.order}-list-item`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={clsx([classes.actionItem])}
                      >
                        <Grid
                          item
                          xs={9}
                          className={clsx([classes.actionButtonContainer])}
                        >
                          {formatAction(item)}
                        </Grid>
                        <Grid
                          container
                          item
                          xs={3}
                          spacing={2}
                          justify="flex-end"
                        >
                          <Grid item xs={4} md={2} style={{
                            visibility: item.actionTypeID === ROUTINE_TYPE.OCCUPANCY_COUNT ||
                              item.actionTypeID === ROUTINE_TYPE.NON_RESET_COUNT ? 'hidden' : 'visible'
                          }}>
                            <IconButton
                              data-id="edit-icon-button"
                              color="primary"
                              alt="Edit"
                              onClick={() => {
                                handleEditAction(item);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <IconButton
                              data-id="delete-icon-button"
                              color="primary"
                              onClick={() => {
                                handleDeleteAction(item.order);
                              }}
                              alt="Delete"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <IconButton
                              data-id="menu-filter-button"
                              color="primary"
                              alt="Drag"
                              {...provided.dragHandleProps}
                            >
                              <MenuIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </Grid>
  );
};
export default RoutineThen;
