import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  },
  composerContainer: {
    padding: 15
  },
  routineName: {
    marginBottom: theme.spacing(2)
  },
  whenThenText: {
    margin: theme.spacing(1),
    fontWeight: "normal"
  },
  systemEventDropdown: {
    minWidth: theme.spacing(25)
  },
  cancelButton: {
    marginLeft: theme.spacing(1)
  },
}));
