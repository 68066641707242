import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  roamingCCTerminalContainer: {
    width: "100%"
  },
  paper: {
    width: "100%",
    margin: theme.spacing(3),
    minHeight: "calc(500%-16px)",
    maxHeight: "calc(500%-23px)"
  },
  addButton: {
    margin: theme.spacing(2)
  },
  paginationContainer: {
    display: "inline-block",
    verticalAlign:"middle",
    justifyContent: "right",
    display: "flex",
    margin: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
    },
  },
  buttonContainer: {
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
    }
  },
  cardHeader: {
    backgroundColor: theme.palette.header,
    color: theme.palette.text.secondary,
    ...theme.typography.button,
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
    },
  },
  title: {
    margin: theme.spacing(3)
  },
}));
