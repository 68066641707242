import React from "react";
import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

const BackButton = () => {
  const history = useHistory();
  return (
    <div>
      <IconButton
        data-testid="routine-history-backbutton"
        onClick={() => {
          history.goBack();
        }}
      >
        <ArrowBack fontSize="large" />
      </IconButton>
    </div>
  );
};

export default BackButton;
