import React, { useRef } from "react";
import { Grid, TextField, Typography, Button } from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope as emailIcon } from "@fortawesome/pro-light-svg-icons";
import { ROUTINE_TYPE } from "../../../../constants/index";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ActionForm from "./ActionForm";

async function isValidEmail(email) {
  try {
    await Yup.string().email().validate(email);
  } catch (error) {
    return false;
  }
  return true;
}

export const emailAddEditSchema = Yup.object().shape({
  ToAddress:  Yup
  .string().trim()
  .test(
    'email',
    'Invalid email(s)',
    function(item) {
      let emails = item.replace(',', ';').replace(' ', '').split(';');
      let valid = true;
      emails.forEach(email => {
        valid = valid && isValidEmail(email);
      });
      return valid;
    }).required("Required"),
  Subject: Yup.string().trim().required("Required"),
  Body: Yup.string().trim().required("Required"),
});

const EmailForm = ({ handleSave, handleCancel, action, isEvent }) => {
  const classes = useStyles();
  const focusedField = useRef("");
  const toAddressRef = useRef(null);
  const subjectRef = useRef(null);
  const bodyRef = useRef(null);

  let defaults = {
    ToAddress: "",
    Subject: "",
    Body: "",
  };

  const formatAndSave = (values) => {
    var template = {
      payload: {
        Body: values.Body,
        Subject: values.Subject,
        ToAddress: values.ToAddress
      },
      actionTypeID: ROUTINE_TYPE.Email,
      order: action?.order ?? 0,
    };
    handleSave(template);
  };

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    trigger,
    formState: { errors, isSubmitting ,isValid},
  } = useForm({
    defaultValues: defaults,
    resolver: yupResolver(emailAddEditSchema),
    mode: "onChange",
  });

  React.useEffect(() => {
    if (action) reset(action?.payload);
  }, [action]);

  const handleInsertTag = (tag) => {
    let fieldRef;

    if (focusedField.current === "ToAddress") {
      toAddressRef.current.focus();
    } else if (focusedField.current === "Subject") {
      fieldRef = subjectRef.current;
    } else if (focusedField.current === "Body") {
      fieldRef = bodyRef.current;
    }

    if (fieldRef) {
      const { selectionStart, selectionEnd, value } = fieldRef;
      const newValue = value.slice(0, selectionStart) + `{${tag}}` + value.slice(selectionEnd);

      setValue(focusedField.current, newValue);

      trigger(focusedField.current);

      setTimeout(() => {
        fieldRef.focus();
        fieldRef.setSelectionRange(selectionStart + tag.length + 2, selectionStart + tag.length + 2);
      }, 0);
    }
  };

  return (
    <>
      <ActionForm
        handleSave={formatAndSave}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        icon={
          <FontAwesomeIcon icon={emailIcon} />
        }
        edit={action}
        primaryText={"Email"}
        secondaryText={"Send an email to an email address"}
        isValid = {isValid}
      >
        <Grid container className={clsx([classes.drawerContainer])}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Controller
              name="ToAddress"
              control={control}
              render={({ field }) => (
                <Grid container>
                  <Grid container item xs={12} sm={4} alignItems="center">
                    <Typography
                      variant="subtitle1"
                      id="ToAddressLabel"
                      className={clsx([classes.severityLabel])}
                    >
                      To Address
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      {...field}
                      data-id={"emailToAddress"}
                      data-testid={"emailToAddress"}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      inputRef={toAddressRef}
                      id="ToAddress"
                      error={!!errors.ToAddress}
                      helperText={errors.ToAddress && errors.ToAddress.message}
                      onFocus={() => (focusedField.current = "ToAddress")}
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Controller
              name="Subject"
              control={control}
              render={({ field }) => (
                <Grid container>
                  <Grid container item xs={12} sm={4} alignItems="center">
                    <Typography
                      variant="subtitle1"
                      id="SubjectLabel"
                      className={clsx([classes.severityLabel])}
                    >
                      Subject
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      {...field}
                      data-id={"emailSubject"}
                      data-testid={"emailSubject"}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      inputRef={subjectRef}
                      id="Subject"
                      error={!!errors.Subject}
                      helperText={errors.Subject && errors.Subject.message}
                      onFocus={() => (focusedField.current = "Subject")}
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Controller
              name="Body"
              control={control}
              render={({ field }) => (
                <Grid container>
                  <Grid container item xs={12} sm={4} alignItems="center">
                    <Typography
                      variant="subtitle1"
                      id="Body"
                      className={clsx([classes.severityLabel])}
                    >
                      Body
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      {...field}
                      data-testid={"emailBody"}
                      data-id={"emailBody"}
                      variant="outlined"
                      margin="normal"
                      required
                      multiline
                      rows={9}
                      fullWidth
                      inputRef={bodyRef}
                      id="Body"
                      error={!!errors.Body}
                      helperText={errors.Body && errors.Body.message}
                      onFocus={() => (focusedField.current = "Body")}
                    />
                    {isEvent && (
                      <Grid container spacing={1} alignItems="center" style={{ marginTop: 10 }}>
                        Tags: 
                        {["Facility Name", "Device Name", "Event Name", "Alert Timestamp"].map((tag) => (
                          <Grid item key={tag}>
                            <Button
                              variant="contained"
                              className={clsx("tagBtn", classes.tagbutton)}
                              onClick={() => handleInsertTag(tag)}
                            >
                              {tag}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
        </Grid>
      </ActionForm>
    </>
  );
};
export default EmailForm;
