import React from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  ListItem,
  List,
  ListItemIcon,
  Tooltip,
} from "@material-ui/core";
import { useStyles } from "./styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Check, ErrorOutline } from "@material-ui/icons";
import BackButton from "./BackButton";
import useCurrentFacilityTimezone from "../../../hooks/useCurrentFacilityTimezone";
import { ROUTINE_TYPE, ROUTINE_TYPE_NAME } from "../../../constants/index";

const trimEllip = (s, length) => {
  return s && s.length > length ? s.substring(0, length) + "..." : s;
};

const SuccessfulIcon = ({ success, classes, tooltip }) => {
  var icon = success ? (
    <Check className={classes.successIcon} />
  ) : (
    <ErrorOutline className={classes.failIcon} />
  );
  return (
    <>
      {tooltip ? (
        <Tooltip title={trimEllip(tooltip, 30)}>{icon}</Tooltip>
      ) : (
        icon
      )}
    </>
  );
};

const RoutineHistoryDetails = ({ routineWithHistory }) => {
  const classes = useStyles();
  const { convertUtcDateToFormat } = useCurrentFacilityTimezone();

  return (
    <div className={classes.container} data-testid="history-details">
      <div className={classes.header}>
        <Typography
          variant="h4"
          data-testid="routine-history-name"
          data-name={routineWithHistory.name}
        >
          {routineWithHistory.name}
        </Typography>
        <BackButton></BackButton>
      </div>

      {!routineWithHistory.instances ||
      routineWithHistory.instances.length == 0 ? (
        <Typography
          variant="subtitle1"
          className={classes.recentHistoryText}
          data-testid="routine-no-history-text"
          data-text="No history found for this routine"
        >
          No history found for this routine
        </Typography>
      ) : (
        <Typography
          variant="h6"
          className={classes.recentHistoryText}
          data-testid="routine-history-header"
          data-text="Recent History"
        >
          Recent History
        </Typography>
      )}
      {routineWithHistory.instances?.map((historyItem, index) => (
        <Accordion
          key={index}
          data-testid="routine-history-line-panel"
          data-id={historyItem.routineInstanceID}
          data-time={convertUtcDateToFormat(historyItem.startDate)}
          data-success={historyItem.successful}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.historyItemAccordianSummary}>
              <Typography>
                {convertUtcDateToFormat(historyItem.startDate)}
              </Typography>
              <SuccessfulIcon
                success={historyItem.successful}
                classes={classes}
              ></SuccessfulIcon>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <List component="nav" data-testid="action-list">
                {historyItem.actions?.map((action, index) => (
                  <ListItem
                    key={index}
                    data-testid="action"
                    data-success={action.successful}
                    data-type={action.actionType}
                  >
                    <ListItemIcon>
                      <SuccessfulIcon
                        success={action.successful}
                        classes={classes}
                        tooltip={action.errorMessage}
                      ></SuccessfulIcon>
                    </ListItemIcon>
                    <Typography>
                      <span data-testid="text-action-details">
                        {/* To be refactored to its own component once/if other
                        types get enriched details */
                        action.actionTypeID == ROUTINE_TYPE.SMS
                          ? "Text to " +
                            action.payload.To +
                            " " +
                            action.payload.Number
                          : action.actionType}
                      </span>
                    </Typography>
                    {action.errorMessage && (
                      <Typography
                        data-testid="action-error-message"
                        data-error-message={action.errorMessage}
                        className={classes.errorMessage}
                      >
                        {action.errorMessage}
                      </Typography>
                    )}
                  </ListItem>
                ))}
              </List>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default RoutineHistoryDetails;
