import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: "1rem 1rem",
  },
  header: {
    display: "flex",
    flex: "1",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  historyItemAccordianSummary: {
    display: "flex",
    flex: "1",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  errorMessage: {
    marginLeft: "1rem",
  },
  recentHistoryText: {
    marginBottom: "0.5rem",
  },
  successIcon: {
    color: theme.palette.success.dark,
  },
  failIcon: {
    color: theme.palette.error.light,
  }
}));
