import React from "react";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import { useStyles } from "./styles";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightAndArrowDownLeftFromCenter as reversingLaneIcon } from "@fortawesome/pro-light-svg-icons";
import { REVERSE_LANE_MODE, ROUTINE_TYPE } from "../../../../constants/index";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ActionForm from "./ActionForm";

export const reversingLaneAddEditSchema = Yup.object().shape({
    LaneID: Yup.string().required("Required"),
    Mode: Yup.string().required("Required"),
});

const ReversingLaneActionForm = ({ handleSave, handleCancel, action, reversingLanes }) => {
  const classes = useStyles();

  const formatAndSave = (values) => {
    var switchLaneMode = {
      payload: {
        EntityID: reversingLanes.facilityID,
        LaneID: values.LaneID,
        Mode: values.Mode,
      },
      actionTypeID: ROUTINE_TYPE.ReverseLane,
      order: action?.order ?? 0,
    };
    handleSave(switchLaneMode);
  };

  const defaults = {
    LaneID: action?.payload?.LaneID?? "",
    Mode: action?.payload?.Mode?? "",
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: defaults,
    resolver: yupResolver(reversingLaneAddEditSchema),
  });

  return (
    <>
      <ActionForm
        handleSave={formatAndSave}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        icon={
          <FontAwesomeIcon icon={reversingLaneIcon} />
        }
        edit={action}
        primaryText={"Reversing Lane"}
        secondaryText={"Switch lane mode"}
      >
      <Grid container className={clsx([classes.drawerContainer])}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Controller
            name="LaneID"
            control={control}
            render={({ field }) => (
              <FormControl
                fullWidth
                FormLabel="Lane"
                variant="outlined"
              >
                <Grid container>
                <Grid container item xs={12} sm={4} alignItems="center">
                    <Typography
                      variant="subtitle1"
                      id="Lane"
                      className={clsx([classes.laneLabel])}
                    >
                      Lane
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Select
                      {...field}
                      fullWidth
                      displayEmpty
                      data-id={"laneSelect"}
                      data-testid={"laneSelect"}
                      data-value={field?.value}
                      className={clsx([classes.laneDropdown])}
                      variant="outlined"
                      inputProps={{
                        id: "lane-dropdown",
                      }}
                      error={!!errors.LaneID}
                      helperText={
                        errors.LaneID && errors.LaneID.message
                      }
                    >
                      {reversingLanes?.lanes?.map((item) => {
                        return (
                          <MenuItem
                            key={item.laneID}
                            value={item.laneID}
                          >
                            {item.laneName}
                          </MenuItem>
                        );
                      })}

                    </Select>
                    {errors.LaneID && (
                      <div className={clsx([classes.selectError])}>
                        {errors.LaneID.message}
                      </div>
                    )}
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Controller
            name="Mode"
            control={control}
            render={({ field }) => (
              <FormControl
                fullWidth
                FormLabel="Mode"
                variant="outlined"
              >
                <Grid container>
                  <Grid container item xs={12} sm={4} alignItems="center">
                    <Typography
                      variant="subtitle1"
                      id="Mode"
                      className={clsx([classes.modeLabel])}
                    >
                      Mode
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Select
                      {...field}
                      fullWidth
                      displayEmpty
                      data-id={"modeSelect"}
                      data-testid={"modeSelect"}
                      data-value={field?.value}
                      className={clsx([classes.modeDropdown])}
                      variant="outlined"
                      inputProps={{
                        id: "mode-dropdown",
                      }}
                      error={!!errors.Mode}
                      helperText={
                        errors.Mode && errors.Mode.message
                      }
                    >
                      {Object.entries(REVERSE_LANE_MODE)?.map((element) => {
                        if (element[0] != "Unknown") {
                          return (
                            <MenuItem
                              key={element[1]}
                              value={element[0]}
                            >
                              {element[0]}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                    {errors.Mode && (
                      <div className={clsx([classes.selectError])}>
                        {errors.Mode.message}
                      </div>
                    )}
                  </Grid>
                </Grid>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      </ActionForm>
    </>
  );
};
export default ReversingLaneActionForm;