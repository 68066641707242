import React from "react";
import { Grid, FormControl, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobile as textIcon } from "@fortawesome/pro-light-svg-icons";
import { ROUTINE_TYPE } from "../../../../constants/index";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ActionForm from "./ActionForm";
import { TextField } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";

const usPhoneRegex = /^\+1\s?\([0-9]{3}\)\s?[0-9]{3}-[0-9]{4}$/;
export const textActionEditSchema = Yup.object().shape({
  Number: Yup.string()
    .matches(usPhoneRegex, "Phone number must be of format: (111) 123-4567")
    .required("Phone number is required"),
  To: Yup.string().required("A name is required"),
});

const TextActionForm = ({ handleSave, handleCancel, event, action }) => {
  const classes = useStyles();

  const formatAndSave = (values) => {
    var text = {
      payload: {
        Number: values.Number,
        To: values.To,
      },
      actionTypeID: ROUTINE_TYPE.SMS,
      order: action?.order ?? 0,
    };
    handleSave(text);
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(textActionEditSchema),
    mode: "onBlur",
  });

  React.useEffect(() => {
    if (action) {
      reset(action.payload);
    }
  }, [action, reset]);

  return (
    <>
      <ActionForm
        handleSave={formatAndSave}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        icon={
          <FontAwesomeIcon icon={textIcon} />
        }
        edit={action}
        primaryText={"Text"}
        secondaryText={"Send a text to a mobile number"}
      >
        <Grid container className={clsx([classes.drawerContainer])} spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Controller
              name="Number"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth variant="outlined">
                  <Grid container>
                    <Grid container item xs={12} sm={4} alignItems="center">
                      <Typography
                        variant="subtitle1"
                        id="textNumber"
                        className={clsx([classes.numberLabel])}
                      >
                        Mobile Number
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <MuiPhoneNumber
                        {...field}
                        data-testid="phone-number"
                        data-id="phone-number"
                        variant="outlined"
                        className={clsx(classes.mobileNumber)}
                        defaultCountry={"us"}
                        onlyCountries={["us"]}
                        countryCodeEditable={false}
                        required={true}
                        error={Boolean(errors.Number)}
                        helperText={errors.Number?.message}
                        FormHelperTextProps={{
                          className: classes.formError,
                        }}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Controller
              name="To"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth variant="outlined">
                  <Grid container>
                    <Grid container item xs={12} sm={4} alignItems="center">
                      <Typography
                        variant="subtitle1"
                        id="textName"
                        className={clsx([classes.nameLabel])}
                      >
                        Name/Description
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        {...field}
                        type="text"
                        variant="outlined"
                        className={clsx(classes.mobileName)}
                        error={Boolean(errors.To)}
                        helperText={errors.To?.message}
                        FormHelperTextProps={{
                          className: classes.formError,
                        }}
                      ></TextField>
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </ActionForm>
    </>
  );
};
export default TextActionForm;
