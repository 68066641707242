import React from 'react';
import { useStyles } from './style';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  useTheme,
  useMediaQuery,
  Divider,
  Button,
  Typography
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import useHasPermissions from '../../../hooks/useHasPermissions';

export const RoamingCCTerminalsTable = ({
  roamingCCTerminals,
  onSelect,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const { hasPermissions } = useHasPermissions();
  const EditPermission = hasPermissions(["roamingcreditcardterminalsetup.edit"]);
  
  const handleSelected = async (id) => {
    onSelect(id);
  };

  return (
    <Grid container className={clsx('roamingccterminals-container')}>
      {roamingCCTerminals == undefined || roamingCCTerminals == null || roamingCCTerminals?.length <= 0 ? (
      <Grid item xs={12}>
        <Typography className={clsx("no-data-message", classes.centerContentColumn, classes.noDataMessage)}>There is no data to display.</Typography>
      </Grid>
      ) : (
        <Table
          size="small"
          className={clsx('roamingccterminals-table', classes.root)}
        >
          {!small && (
            <TableHead className={clsx('table-head')}>
              <TableRow
                className={clsx(
                  'roamingccterminals-row',
                  classes.headerRow,
                  classes.root
                )}
              >
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <TableCell
                      data-id="roamingccterminals-title-name"
                      data-testid="roamingccterminals-title-name"
                      className={clsx('name', classes.tableCell)}
                    >
                      Name
                    </TableCell>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TableCell
                      data-id="roamingccterminals-title-number"
                      className={clsx('number', classes.tableCell)}
                    >
                      Terminal ID
                    </TableCell>
                  </Grid>
                  <Grid item xs={12} md={4}></Grid>                
                </Grid>
              </TableRow>
              <Divider />
            </TableHead>
          )}
          <TableBody className={clsx('table-body')}>
            {roamingCCTerminals?.map((row, index) => (
              <>
                <TableRow
                  key={index}
                  className={clsx(
                    'roamingccterminals-row',
                    classes.root
                  )}
                  data-id={row.name}
                >
                  <Grid container>
                    <Grid item xs={12} md={4}>
                      <TableCell
                        className={clsx('roamingccterminals-name', classes.tableCell)}
                        data-value={`${row.name}`}
                        data-id={`name-roamingccterminals-${index}`}
                        data-testid={`name-roamingccterminals-${index}`}
                      >
                        {small ? `Name: ${row.name}` : `${row.name}`}
                      </TableCell>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TableCell
                        className={clsx('roamingccterminals-number', classes.tableCell)}
                        data-id={`number-groamingccterminals-${index}`}
                        data-value={row.terminalid ?? 'Unknown'}
                      >
                        {small
                          ? `Terminal #: ${row.terminalid}`
                          : `${row.terminalid}`}
                      </TableCell>
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.buttonContainer}>
                      {EditPermission && (
                        <Button
                          className={clsx('btn-edit', classes.editButton)}
                          data-id={`edit-groamingccterminals-btn-${index}`}
                          data-testid={`edit-groamingccterminals-btn-${index}`}
                          variant="outlined"
                          color="primary"
                          onClick={(e) =>
                            handleSelected(row.id)
                          }
                          startIcon={
                            small && (
                              <EditIcon
                                fontSize="default"
                                className={clsx(classes.icon)}
                              />
                            )
                          }
                        >
                          {!small && 'Edit'}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </TableRow>
                <Divider />
              </>
            ))}
          </TableBody>
        </Table>
      )}
    </Grid>
  );
};

RoamingCCTerminalsTable.defaultProps = {
  roamingCCTerminals: [],
  onSelect: () => {},
};
RoamingCCTerminalsTable.propTypes = {
  roamingCCTerminals: PropTypes.array,
  onSelect: PropTypes.func,
};

export default RoamingCCTerminalsTable;
