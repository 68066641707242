import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  actionItem: {
    border: "1px solid",
  },
  actionButtonContainer: {
    borderLeft: "lpx solid",
  },
  actionText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  routineName: {
    marginBottom: theme.spacing(2),
  },
  whenThenText: {
    margin: theme.spacing(1),
    fontWeight: "normal",
  },
  systemEventDropdown: {
    minWidth: theme.spacing(25),
  },
  cancelButton: {
    marginLeft: theme.spacing(1),
  },
  routineWhenContainer: {
    border: "1px solid",
    borderRadius: "5px",
  },
  routineThenContainer: {},
  routineEventButtonContainer: {
    [theme.breakpoints.up("md")]: {
      borderRight: "1px solid",
    },
    [theme.breakpoints.down("sm")]: {
      borderBottom: "1px solid",
    },
    padding: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  routineScheduleButtonContainer: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
}));
