import { makeStyles, lighten } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "medium",
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  tableCell: {
    fontSize: "1.2em",
    borderBottom: "none",
    padding: theme.spacing(1),
    justifyContent: "left"
  },
  pointer: {
    cursor: "pointer",
  },
  editButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "25%",
      marginLeft: theme.spacing(1)
    }
  },
  buttonContainer: {
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
    }
  },
  centerContentColumn: {
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  noDataMessage: {
    paddingTop: theme.spacing(2),
    fontSize: "1.5rem"
  },
}));
