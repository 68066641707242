import React from "react";
import { 
  Grid, 
  Button, 
  Divider,
  Typography,
  FormControl,
  TextField,
} from "@material-ui/core";
import Title from "../../../Title";
import _ from "lodash";
import { useStyles } from "./styles";
import clsx from "clsx";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import useRoamingCCTerminalState from "../../../../state/slices/cashier/useRoamingCCTerminalState";

const RoamingCCTerminalForm = ({ handleSave, handleEdit, handleDelete, handleCancel, roamingCCTerminal }) => {
  const classes = useStyles();
  const { hasPermissions } = useHasPermissions();
  const DeletePermission = hasPermissions(["roamingcreditcardterminalsetup.delete"]);

  const { roamingCCTerminals } = useRoamingCCTerminalState();

  const roamingCCTerminalSchema = Yup.object().shape({
    displayName: Yup.string().required("Required").max(25, "Maximum of 25 characters"),
    terminalSerialNo: Yup
    .string()
    .required('Required')
    .test('unique','Terminal ID already in use.',(value) =>{
      const terminalSerialNos = roamingCCTerminals
      .filter(roamingCCT => roamingCCT.roamingCCTerminalID !== roamingCCTerminal.roamingCCTerminalID)
      .map((roamingCCT) => roamingCCT.terminalSerialNo);
      return (terminalSerialNos.length) !== new Set([...terminalSerialNos, value]).size;
    }),
  });
  
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {...roamingCCTerminal},
    resolver: yupResolver(roamingCCTerminalSchema),
    shouldUnregister: false,
  });

  const onSubmit = async data => {
    if (roamingCCTerminal.roamingCCTerminalID === undefined) {
      await handleSave(data);
    } else {
      await handleEdit(data);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={8} md={8}  className={clsx([classes.roamingCCTTitle])}>
          <Title>Credit Card Terminal</Title>
        </Grid>
        {(roamingCCTerminal.roamingCCTerminalID && DeletePermission === true) && (
        <Grid item xs={4} md={4} className={clsx([classes.drawerContainer])} >
          <Button
            className={clsx("deleteBtn", classes.btnControl)}
            name="delete"
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            disabled={isSubmitting}
            data-testid="deleteBtn"
          >
          Delete
          </Button>
        </Grid>
        )}
      </Grid>
      <Divider></Divider>
      <Grid container className={clsx([classes.drawerContainer])} >
        <Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
          <Controller
            name="displayName"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  onBlur={  (e) => {
                    field.onChange(e.target.value.trim());
                  }}
                  id="displayName"
                  className={clsx("deviceName", classes.flexInput)}
                  label="Device Name"
                  variant="outlined"
                  error={!!errors.displayName}
                  helperText={errors.displayName && errors.displayName.message}
                />
              </FormControl>
            )}
          />                        
        </Grid>
        <Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
          <Controller
            name="terminalSerialNo"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  onBlur={  (e) => {
                    field.onChange(e.target.value.trim());
                  }}
                  id="terminalSerialNo"
                  label="Terminal ID"
                  className={clsx("uniqueID", classes.flexInput)}
                  variant="outlined"
                  error={!!errors.terminalSerialNo}
                  helperText={errors.terminalSerialNo && errors.terminalSerialNo.message}
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Divider></Divider>
      <Grid container className={clsx([classes.footerContainer])}>
        <Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
          <Button
            className={clsx("cancelBtn", classes.btnControl)}
            name="cancel"
            variant="contained"
            onClick={handleCancel}
            disabled={isSubmitting}
            data-testid="cancelBtn"
          >
          Close
          </Button>
          <Button
            color="primary"
            name="submit"
            type="submit"
            variant="contained"
            className={clsx("saveBtn", classes.btnControl)}
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
            data-testid="submitBtn"
          >
          Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default RoamingCCTerminalForm;