import React from 'react';
import styles from "./style";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';


export const RibbonCard = (props) => {
  const classes = styles();
  return (
    <div className={clsx([classes.root, ...props.classes])} style={props.style} {...props}>

    <Card className={classes.card} data-r={props.showribbon}>
        {props.showribbon && (
            <Tooltip title={props.hover} placement="left">
                <div className={classes.ribbon}>
                    <span  className={classes.span}>{props.text}</span> 
                </div>   
            </Tooltip> 
       )}
       {props.children}
    </Card>
</div>
);
}
export default RibbonCard;