import React, { useState, useEffect } from 'react'
import clsx from "clsx";
import {
	Chip,
	TextField,
	InputAdornment,
	Grid,
	Button
} from "@material-ui/core";


import { useStyles } from "./styles";

const LPRConditional = ({ currentRoutine, setCurrentRoutine, conditionIndex, setConditionalValid }) => {

	const [licensePlate, setLicensePlate] = useState("");

	const classes = useStyles();

	const maxReached = !(currentRoutine?.conditions[conditionIndex].payload.values.length < 100);

	const isAbleToSubmit = licensePlate && !currentRoutine?.conditions[conditionIndex].payload.values.some(lp => lp === licensePlate.trim()) && !maxReached;

	const handleSetLicensePlate = (value) => {
		setLicensePlate(value.toUpperCase());
	}

	const handleLicensePlateSubmit = () => {
		if (isAbleToSubmit) {
			var newConditions = currentRoutine?.conditions;
			newConditions[conditionIndex].payload.values.push(licensePlate.trim());
			setCurrentRoutine({ ...currentRoutine, conditions: newConditions });
			setLicensePlate("");
		}
	}

	const handleLicensePlateDelete = (value) => {
		const newConditions = currentRoutine?.conditions;
		if (newConditions[conditionIndex].payload.values.some(e => e === value)) {
			newConditions[conditionIndex].payload.values = newConditions[conditionIndex].payload.values.filter(e => e !== value);
			setCurrentRoutine({ ...currentRoutine, conditions: newConditions });
		}
	}

	useEffect(() => {
		setConditionalValid(currentRoutine?.conditions[conditionIndex].payload.values.length > 0);
	}, [currentRoutine]);

	return (
		<Grid container spacing={2} className={clsx(["lprcontainer", classes.lprcontainer])}>
			<Grid item xs={12}>...that matches these plates:</Grid>
			<Grid item xs={6}>
				<TextField
					type="text"
					fullWidth
					className={clsx(["inputField", classes.inputField])}
					name={`licenseplateconditionalinput`}
					value={licensePlate}
					disabled={maxReached}
					onChange={(e) => handleSetLicensePlate(e.target.value)}
					onKeyPress={(e) => {
						if (e.key === 'Enter' && isAbleToSubmit) {
							handleLicensePlateSubmit();
						}
					}}
					InputProps={{
						inputProps:{
							"data-testid": `licenseplateconditionalinput`,
							maxLength: 8,
							style: { textTransform: "uppercase" }
						}
					}}
				/>
			</Grid>
			<Grid item xs={6} justifyContent="flex-start">
				<Button
					data-testid="addBtn"
					alt={maxReached ? "Max Limit has been reached" : ""}
					title={maxReached ? "Max Limit has been reached" : ""}
					className={clsx(["addBtn", classes.addBtn])}
					onClick={() => handleLicensePlateSubmit()}
					disabled={!isAbleToSubmit}
					color="primary"
					size="small"
					variant="contained"
				>
					Add
				</Button>
				<span
					className={clsx(["amountDisplay", classes.amountDisplay])}
				>
					{currentRoutine?.conditions[conditionIndex].payload.values.length}/100
				</span>
			</Grid>
			<Grid className={clsx("chipcontainer", classes.chipContainer)} item xs={12}>
			{
				currentRoutine?.conditions[conditionIndex].payload.values.filter(lp => lp.includes(licensePlate)).length === 0 ?
				<span>
					Nothing Found
				</span> :
				currentRoutine?.conditions[conditionIndex].payload.values.filter(lp => lp.includes(licensePlate)).sort().map(lp => {
					return (
						<Chip
							data-testid="licenseplatechip"
							data-plate={lp}
							className={clsx("chip", classes.chip)}
							label={lp}
							key={lp}
							onDelete={() => handleLicensePlateDelete(lp)}
						/>
					)
				})
			}
			</Grid>
		</Grid>
	)
}

export default LPRConditional;