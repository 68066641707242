import React from "react";
import { 
    Grid, 
    Button, 
    Divider,
    Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";

const ActionForm = ({ handleSubmit, handleCancel, handleSave, icon, primaryText, secondaryText, children, edit, isValid}) => {
    const classes = useStyles();

    const FormatAndSave = async (values) => {
        await handleSave(values);
    }

    return (
        <>
            <Grid container>
                <Grid item xs={1}>
                    <Typography variant="h5" className={clsx([classes.actionIcon])}>{icon}</Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography variant="h5" className={clsx([classes.actionHeader])}>{primaryText}</Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={11}>
                    <Typography variant="subtitle1" className={clsx([classes.actionText])}>{secondaryText}</Typography>
                </Grid>
            </Grid>
            <form className={clsx([classes.form])} onSubmit={handleSubmit((values) => FormatAndSave(values))} noValidate>
                <Divider></Divider>
                {children}
                <Grid item xs={12} md={12} lg={12} className={clsx([classes.actionContainer])}>
                    <Divider></Divider>
                    <Button
                    className={clsx("cancelBtn", classes.btnControl)}
                    data-id="cancelBtn"
                    name="cancel"
                    variant="contained"
                    onClick={handleCancel}
                    >
                        Close
                    </Button>
                    <Button
                        color="primary"
                        data-id="saveBtn"
                        name="Save"
                        disabled={isValid === false}
                        type="submit"
                        variant="contained"
                        className={clsx("saveBtn", classes.btnControl)}
                    >
                        {edit ? "Save" : "Add"}
                    </Button>
                </Grid>
            </form>
        </>
    );
};
export default ActionForm;
