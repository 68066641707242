import { makeStyles } from "@material-ui/core/styles";
import blue  from '@material-ui/core/colors/blue';

const styles = makeStyles(theme => ({

    root: {
        margin: '0 auto',
        position:"relative",
        display:"inline-block",
    },
    card: {
        width:"100%",
        position: "relative",
        '&[data-r="true"]' :{
            backgroundColor:theme.palette.highlight.main,//"rgba(255, 253, 214, 0.5)",
           
        },
    },
    ribbon: {
        backgroundColor: theme.palette.warning.light,
        opacity:.80,
        position: "absolute",
        color: 'white',
        width: 150,
        zIndex: 3,
        textAlign: 'center',
        textTransform: 'uppercase',
        padding: 5,
        top:23, 
        right:-40,
        fontSize: "85%",
        '&::before': {
            position: "absolute",
            zIndex: -1,
            content: '',
            display: 'block',
            border: '5px solid #2980b9',
        },
        '&::after': {
            position: "absolute",
            zIndex: -1,
            content: '',
            display: 'block',
            border: '5px solid #2980b9',
        },
        transform: 'rotate(45deg)',
    },
    span: {
    
    }   
    

}));

export default styles;