import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  roamingCCTTitle: {
    padding: theme.spacing(1),
  },
  drawerContainer: {
    padding: theme.spacing(1),
  },
  drawerItems: {
    padding: theme.spacing(1),
  },
  btnControl: {
    margin: theme.spacing(1),
    float: "right"
  },
  flexInput: { 
    flexGrow: "1"
  },
  footerContainer: {
  },  
}));
