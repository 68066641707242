import React from "react";
import { useSelector } from "react-redux";
import { Drawer } from "@material-ui/core";
import useHasPermissions from "../../hooks/useHasPermissions";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import {useCashierContext}  from "../../providers/CashierProvider";
import { 
  onAddRoamingCCT, 
  onEditRoamingCCT, 
  onCloseRoamingCCT 
} from "../../reducers/cashier/roamingCCTerminalEditReducer";
import RoamingCCTerminalContainer from "../../components/RoamingCCTerminals/RoamingCCTerminalContainer";
import CCTerminalComposer from "../../components/RoamingCCTerminals/RoamingCCTerminalComposer";
import { useStyles } from "./styles";

const RoamingCCTerminals = () => {
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);

  const { hasPermissions } = useHasPermissions();
  const EditPermission = hasPermissions(["roamingcreditcardterminalsetup.edit"]);
  const AddPermission = hasPermissions(["roamingcreditcardterminalsetup.add"]);
  const ViewPermission = hasPermissions(["roamingcreditcardterminalsetup.view"]);

  const { roamingCCTerminalEditData } = useCashierContext();
  const [values, dispatch] = roamingCCTerminalEditData;

  const handleAdd = () => {
    dispatch({ 
      type: onAddRoamingCCT
    });
  };

  const handleEdit = roamingCCTerminalID => {
    dispatch({
      type: onEditRoamingCCT, 
      payload: {
        roamingCCTerminalID }
      });
  };

  const handleCancel = () => {
    dispatch({ 
      type: onCloseRoamingCCT
    });
  };

  const handleSaved = () => {
    dispatch({ 
      type: onCloseRoamingCCT
    });
  };

  return (
    <>
      {ViewPermission && (
      <RoamingCCTerminalContainer
        onAddClick={handleAdd}
        onEditClick={handleEdit}
      />
      )}
      {(AddPermission || EditPermission ) && (
      <Drawer
        anchor="right" 
        open={values.showForm}
        classes={{ paper: classes.drawerPaper, root: classes.drawer}}
      >
        <CCTerminalComposer
            entityID={scopeAwareFacilityID}
            roamingCCTerminalID={values.roamingCCTerminalID}
            handleSave={handleSaved}
            handleCancel={handleCancel}
        />
      </Drawer>
      )}
    </>
  );
};
export default RoamingCCTerminals;