import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useScheduledReportsData } from "../../../providers/ScheduledReportsProvider";
import { onFiltersChanged } from "../../../reducers/scheduledReports/scheduledReportsReducer";
import ScheduledReportsService from "../../../services/ScheduledReportsService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { FindEntity } from "../../../state/slices/entities";
import ListView from "../../ListView/ListView";
import moment from "moment";
import { isEmpty } from "lodash";

const scheduledReportsService = new ScheduledReportsService(apiClient);

const ScheduledReportsList = ({ onSelect, onAddClick }) => {
  const entities = useSelector(state => state.entities);
  const facilityID = entities.ContextID;
  const classes = useStyles();
  const tz = useSelector(
    (state) =>
      FindEntity(state.entities?.EntityList ?? [], facilityID)?.details
        ?.timezone
  );
  const { hasPermissions } = useHasPermissions();
  const [scheduledReports, setScheduledReports] = useState({
    display: []
  });

  const { scheduledReportsData } = useScheduledReportsData();
  const [schldRprtsData, scheduledReportsDispatch] = scheduledReportsData;
  const enqueueSnackbar = useEnqueueSnackbar();

  const fillScheduledReportsList = useCallback(
    async (facilityID) => {
      let response;
      try {
        response = await scheduledReportsService.getScheduledReports(
          facilityID
        );
      } catch (e) {
        enqueueSnackbar("Failed to retrieve Scheduled Reports", {
          variant: "error",
          tag: "FailedToRetrieveScheduledReports"
        });
        return;
      }
      setScheduledReports(response.data);
      formatScheduledReportsData(response.data);
    },
    [
      facilityID
    ]
  );

  useEffect(() => {
    fillScheduledReportsList(
      facilityID
    );
  }, [
    facilityID
  ]);


  const formatScheduledReportsData = rawScheduledReports => {
    let mappingList = [];

    rawScheduledReports.map(scheduledReports => {
      mappingList.push({
        id: scheduledReports.scheduledrptid,
        report: scheduledReports.displayname,
        fromEmail: scheduledReports.email,
        "report period": scheduledReports.reporttype,
        when: scheduledReports.reportperiod,
        "to whom": scheduledReports.emails,
        "last run": formatDate(scheduledReports.reportlastrun),
        enabledSwitch: scheduledReports.enabled,
        reportName: scheduledReports.reportname
      });
    });

    setScheduledReports(prev => ({ ...prev, display: mappingList }));
  };

  const formatDate = (date) => {
    return isEmpty(date)
      ? ""
      : moment
          .utc(date)
          .tz(tz)
          ?.format("MM/DD/YYYY hh:mm:ss a");
  };

  const handleAddScheduledReports = () => {
    onAddClick();
  };

  const handleEditScheduledReports = () => {
    onSelect();
  };

  const handlePageChange = (e, page) => {
    scheduledReportsDispatch({
      type: onFiltersChanged,
      payload: { scheduledReportsPage: page }
    });
  };

  return (
    <>
      <div className={classes.root}>
        <ListView
            handleAddButton={handleAddScheduledReports}
            handleEditButton={handleEditScheduledReports}
            deleteButtonToggle={false}
            editButtonEnabledFunc={(row) => {return hasPermissions([row.reportName])}}
            showTableHead={true}
            titleBarTitleText="Scheduled Reports"
            titleBarToggle={true}
            titleBarTextToggle={true}
            paginationToggle={true}
            data={scheduledReports?.display}
            handlePageChange={handlePageChange}
            rowsToShowPerPage={[15, 25, 50]}
            rowsToShowDefault={15}
            currentPage={schldRprtsData.scheduledReportsPage ?? 1}
            isSwitchToggleEnabled = {true}
            sortCaseInsensitive={true}
            sortByID={true}
        />
      </div>
    </>
  );
};

ScheduledReportsList.defaultProps = {
  onSelect: () => {},
  onAddClick: () => {}
};

ScheduledReportsList.propTypes = {
  onSelect: PropTypes.func,
  onAddClick: PropTypes.func
};

export default ScheduledReportsList;
