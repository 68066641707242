import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { CircularProgress, Box, Typography } from "@material-ui/core";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import RoutineHistoryService from "../../../services/RoutineHistoryService";
import { useStyles } from "./styles";
import RoutineHistoryDetails from "./RoutineHistoryDetails";
import BackButton from "./BackButton";

const routineHistoryService = new RoutineHistoryService(apiClient);

const RoutineHistory = ({}) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [routineID, setRoutineID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [showRoutineHistory, setShowRoutineHistory] = useState(false);
  const [routineWithHistory, setRoutineWithHistory] = useState(null);
  let { state } = useLocation();

  useEffect(() => {
    if (state?.routineId) {
      setRoutineID(state?.routineId);
    } else {
      setFailed(true);
    }
  }, [state]);

  useEffect(() => {
    if (routineID) {
      getRoutineHistory();
    }
  }, [routineID]);

  useEffect(() => {
    setShowRoutineHistory(routineWithHistory && loading === false);
  }, [routineWithHistory, loading]);

  const getRoutineHistory = useCallback(async () => {
    setLoading(true);

    try {
      var response = await routineHistoryService.getHistoryForRoutine(routineID, 10);
      if (response.status === 200) {
        setRoutineWithHistory(response.data);
        setFailed(false);
      }
    } catch {
      enqueueSnackbar("Failed to retrieve routine history", {
        variant: "error",
        tag: "fetchRoutinesError",
      });
      setFailed(true);
    }

    setLoading(false);
  }, [routineID]);

  return (
    <>
      {showRoutineHistory && <RoutineHistoryDetails routineWithHistory={routineWithHistory}></RoutineHistoryDetails>}
      {loading && (
        <Box height="25vh" display="flex" flexDirection="column" alignItems="center" justifyContent="center" data-testid="loading-box">
          <CircularProgress size="3rem" />
          <Typography variant="h5">Loading</Typography>
        </Box>
      )}
      {failed === true && (
        <Box height="25vh" display="flex" flexDirection="column" alignItems="center" justifyContent="center" data-testid="failure-box">
          <Typography variant="h5">Failed to find routine history</Typography>
          <BackButton></BackButton>
        </Box>
      )}
    </>
  );
};

export default RoutineHistory;
