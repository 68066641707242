import React from "react";
import _ from "lodash";
import RoamingCCTerminalForm from "./RoamingCCTerminalForm"
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import {useCashierContext}  from "../../../providers/CashierProvider";
import {useDispatch} from "react-redux";
import { 
  fetchRoamingCCTerminals, 
  removeRoamingCCTerminals 
} from "../../../state/slices/cashier/cashier";
import useRoamingCCTerminalState from "../../../state/slices/cashier/useRoamingCCTerminalState";

const CCTerminalComposer = ({ entityID, roamingCCTerminalID, handleCancel, handleSave}) => {
  const defaultRoamingCCT = {
    roamingCCTerminalID: undefined,
    entityID: entityID,
    displayName: "",
    terminalSerialNo: "",
  };
  const { textConfirmation } = useConfirmationDialog();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { 
    functions : { createRoamingCCTerminal, deleteRoamingCCTerminal, updateRoamingCCTerminal }
  } = useCashierContext();
  const dispatch = useDispatch();
  const { roamingCCTerminals } = useRoamingCCTerminalState();
  
  const formatCCTs = (roamingCCTs) => {    
      const selectedCCT = roamingCCTs?.filter(roamingCCT => roamingCCT.roamingCCTerminalID === roamingCCTerminalID);
      return selectedCCT.length? selectedCCT[0] : defaultRoamingCCT;
  };
  
  const addRoamingCCT = async (data) => {
    try {
      const result = await createRoamingCCTerminal(data);
      dispatch(fetchRoamingCCTerminals(result.data.entityID)); 
    } catch (ex) {
      enqueueSnackbar("Failed to create roaming CCTerminal", {
        variant: "error",
        tag: "FailedToCreateRoamingCCTerminalInfo"
      });
    }
    handleSave();
  };

  const editRoamingCCT = async (data) => {
    try {
      const result = await updateRoamingCCTerminal(data);
      dispatch(fetchRoamingCCTerminals(result.data.entityID)); 
    } catch (ex) {
      enqueueSnackbar("Failed to update roaming CCTerminal", {
        variant: "error",
        tag: "FailedToUpdateRoamingCCTerminalInfo"
      });
    }
    handleSave();
  };

  const deleteRoamingCCT = async () => {
    const selectedCCT = roamingCCTerminals.filter(roamingCCT => roamingCCT.roamingCCTerminalID === roamingCCTerminalID)[0];
    const response = await textConfirmation({
      title: "Delete Confirmation",
      message: `If you're sure you want to delete it, please enter the device name in the input box below.`,
      textConfirmation: selectedCCT.displayName,
    });
    if (response===false) return;
    try {
      await deleteRoamingCCTerminal(selectedCCT.roamingCCTerminalID);
      dispatch(removeRoamingCCTerminals(selectedCCT.roamingCCTerminalID));
    } catch {
      enqueueSnackbar("Failed to delete roaming CCTerminal", {
        variant: "error",
        tag: "FailedToDeleteRoamingCCTerminalInfo"
      });
    }    
    handleSave();
  };

  return (
    <RoamingCCTerminalForm
      handleSave={addRoamingCCT}
      handleEdit={editRoamingCCT}
      handleDelete={deleteRoamingCCT}
      handleCancel={handleCancel}
      roamingCCTerminal={formatCCTs(roamingCCTerminals)}
    />
  )
};
export default CCTerminalComposer;