import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1),
	},
	chip: {
		margin: theme.spacing(.5)
	},
	chipContainer:{
		height: '130px',
		overflowY: "auto"
	},
	inputField: {
		marginRight: theme.spacing(.5)
	},
	addBtn:{
		marginLeft: theme.spacing(.5),
		marginRight: theme.spacing(.5),
		pointerEvents: "auto !important"
	},
	amountDisplay: {
		marginLeft: theme.spacing(.5),
		width: '40px',
		display: 'inline-block',
		textAlign: 'right',
		verticalAlign: 'bottom',
		fontSize: '10px',
		color: 'grey'
	},
	lprcontainer: {
		paddingTop: theme.spacing(4.0),
	}
}));